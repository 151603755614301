.b-product-tile {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    user-select: none;

    @media (--xxl) {
        font-size: $font-size-primary;
    }

    &__top {
        margin-bottom: 25px;
        position: relative;

        @media (--xxl) {
            margin-bottom: 32px;
        }

        .b-carousel & {
            margin-bottom: 10px;

            &--images {
                margin-bottom: 25px;
            }
        }
    }

    &__images {
        overflow: hidden;
        position: relative;
        margin-bottom: 15px;
    }

    &__double-images,
    &__single-image {
        display: block;
        font-size: 0;
    }

    &__double-images {
        transform: translate3d(0, 0, 0);
        transition: transform $transition-ease;
        width: 200%;

        .m-alternative & {
            transform: translate3d(-50%, 0, 0);
        }

        @media (--ie) {
            left: 0;
            position: relative;
            transform: none;
            transition-property: left;

            .m-alternative & {
                left: -100%;
                transform: none;
            }
        }
    }

    &__promo a:hover {
        @if ($feature-redesign) {
            text-decoration: none;
        }
    }

    &__promo-banner {
        @if ($feature-redesign) {
            height: 26px;
            font-size: $font-size-primary;
            text-align: left;
            padding-left: 5px;
            margin-bottom: 8px;
            line-height: 26px;
            font-family: $font-family-sans;
            overflow: hidden;
        }
    }

    &__image,
    & .b-product-picture {
        width: 100%;
        background-image: url('../images/coming-soon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 36%;
    }

    &__double-images &__image,
    &__double-images .b-product-picture {
        width: percentage(1*0.5);
    }

    &__image-switcher {
        cursor: pointer;
        display: flex;
        justify-content: center;
        left: 0;
        padding: 5px;
        position: absolute;
        top: 100%;
        width: 100%;

        @media (--xxl) {
            padding: 8px;
        }
    }

    &__image-dot {
        background: none;
        border: 0;
        color: $black;
        font-size: 5px;
        height: 15px;
        padding: 5px;
        width: 15px;

        @media (--xxl) {
            font-size: 6px;
        }

        &.m-alternative {
            color: $gray-200;
        }

        .m-alternative & {
            &.m-default {
                color: $gray-200;
            }

            &.m-alternative {
                color: $black;
            }
        }
    }

    &__link {
        display: inline-block;
        vertical-align: top;

        @if ($feature-redesign) {
            &:hover {
                opacity: 1 !important;
                color: $earthen-brown;
            }
        }
    }

    &__name {
        @if ($feature-redesign) {
            @include n5;
        }

        margin-bottom: 5px;
    }

    &__button {
        font-size: $font-size-secondary;
        height: auto !important; // to avoid animation during button appearance
        min-width: 136px;

        @media (--sm) {
            font-weight: 400;

            @if ($feature-redesign) {
                font-weight: normal;
            }

            letter-spacing: 0.03em;
            min-width: 116px;
            padding-bottom: 8px;
            padding-top: 8px;

            .grid-alternative-view & {
                display: none;
            }
        }

        &.m-active,
        &.m-active:not(:disabled):not(.m-disabled) {
            background-color: $gray-100;
            border-color: transparent;
            color: $gray-300;
            pointer-events: none;
        }
    }

    &__button-text {
        align-items: center;
        display: flex;
        justify-content: center;

        &--added {
            display: none;
        }
    }

    &__button.m-active &__button-text {
        &--default {
            display: none;
        }

        &--added {
            display: flex;
        }
    }

    &__wishlist {
        background: none;
        border: 0;
        color: $black;
        font-size: 16px;
        margin: 0;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 0;

        &.m-active {
            &::before {
                content: icons-char(wishlist-filled);
            }
        }

        @media (--sm) {
            .grid-alternative-view & {
                font-size: 11px;
                padding: 10px;
            }
        }
    }

    &__remove {
        color: $black;
        font-size: 14px;
        margin: 0;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 0;

        @media (--lg) {
            font-size: 10px;
        }

        @media (--xl) {
            font-size: 12px;
            padding: 16px;
        }
    }

    &__swatches {
        @if ($feature-redesign) {
            font-family: $font-family-sans;
        }

        margin-top: 10px;

        &__plp-tile {
            height: 24px;
            display: flex;
            margin-top: 10px;

            .plp-swatches-wrap {
                display: flex;

                .swatch-more {
                    opacity: 0.5;
                    line-height: 10px;
                    display: none;
                    align-items: center;
                    padding-top: 2px;
                }
            }

            &:hover {
                a {
                    display: none;
                }

                .b-product-variation {
                    &__swatch {
                        opacity: 1;
                        transition: opacity 0.25s;
                    }
                }
            }

            &:not(:hover) {
                a {
                    display: inline-block;
                    opacity: 0.5;
                }

                .plp-swatches-wrap {
                    opacity: 0;
                }
                @media (min-width: 768px) {
                    .plp-swatches-wrap {
                        width: 0px;
                    }
                }
            }


            .b-product-variation {
                &__swatch {
                    margin: 0 5px 0 0;
                    height: 24px;
                    width: 24px;
                }
            }

            @media (max-width: 767px) {
                .plp-swatches-wrap {
                    >button:nth-child(5)~button {
                        display: none;
                    }

                    .swatch-more {
                        display: flex;
                    }
                }

                &:not(:hover) {
                    a {
                        display: none;
                    }

                    .plp-swatches-wrap {
                        opacity: 1;
                    }
                }
            }

                .b-carousel & {
                    .plp-swatches-wrap {
                        display: none !important;
                    }
                    .b-product-tile__swatch-link {
                        display: block !important;
                    }
                }
        }
    }

    &__swatch-link {
        font-family: $font-family-serif;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.0625;

        @if ($feature-redesign) {
            @include n10;
            color: $hush-black;
            text-decoration: none;
        }

        @media (min-width: 769px) {
            font-size: 0.9375rem;

            @if ($feature-redesign) {
                @include n10;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        letter-spacing: 0.8px;
    }

    &__details {
        flex-grow: 1;
    }

    &__quick-buy,
    &__badge {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    &__quick-buy-inner,
    &__badge {
        background-color: rgba($white, 0.85);
        transition: transform $transition-ease;

        @if ($feature-redesign) {
            background-color: rgba($hush-white, 0.85);
        }
    }

    &__quick-buy {
        pointer-events: none;

        .m-quickbuy & {
            pointer-events: initial;
        }
    }

    &__quick-buy-inner {
        padding: 15px 15px 20px;
        transform: translate3d(0, 101%, 0);

        .m-quickbuy & {
            transform: translate3d(0, 0, 0);
        }
    }

    &__badge {
        @if ($feature-redesign) {
            @include n10;
            text-transform: none;
        }

        transform: translate3d(0, 0, 0);
        transition-delay: $transition-timing;

        .m-quickbuy & {
            transform: translate3d(0, 101%, 0);
            transition-delay: 0s;
        }
    }

    &__double-images &__badge {
        width: percentage(1*0.5);
    }

    &__actions {
        align-self: flex-end;
        padding-left: 10px;
    }

    &__add-to-bag {
        font-size: 12px;
        padding: 15px;
        text-align: center;
    }

    &--bundle {
        .strike-through {
            display: none;
        }
    }

    .b-product-availability {
        width: 100%;

        &__message {
            margin-top: 15px;
        }
    }
}

/* Turns on/off swatches on desktop with CS var*/
.l-search__products {
    &.cs-enable-display-color-swatch {
        .b-product-tile {
            &__swatches {
                &__plp-tile {
                    a {
                        display: none !important;
                    }

                    .plp-swatches-wrap {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.l-search__tab {
    .plp-swatches-wrap {
        display: none !important;
    }
    .b-product-tile__swatch-link {
        display: block !important;
    }
}

#pdp-duel-carousel {

    .b-product-tile {

        .b-product-picture {
            position: relative;

            picture img {
                display: block;
                transition: transform 0.3s linear;
            }
        }
        .product-influencer {
            font-family: $font-family-sans;
            position: absolute;
            top: calc(50% - 11px);
            width: 100%;
            text-align: center;
            color: $hush-white;
            display: none;
            font-size: 15.3px;
            line-height: 21.6px;
            height: 22px;
        }

        .b-product-tile__single-image:hover {

            .product-influencer{
                display: block;
            }

            picture img {
                filter: grayscale(100%) brightness(70%);
                transform: scale(1.05); 
            }
        }
    }
}