.b-homepage-footer {
    @include setup-grid-gutters((
        sm:  20px,
        md:  80px,
        lg:  160px,
        xl:  172px,
        xxl: 220px,
    ), false);

    font-size: $font-size-primary;
    letter-spacing: 0.5px;
    line-height: 1.31;
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 20px;

    &__title {
        font-family: $font-family-tiempos-fine;
        font-size: 16px;
        font-weight: 700;

        @if ($feature-redesign) {
            font-family: $font-family-serif;
            font-weight: bold;
        }
        letter-spacing: 1px;
        line-height: normal;
        text-transform: none;
    }

    @media (--md-up) {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    &__item {
        @media (--md-up) {
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }

    &__item + &__item {
        @media (--md-up) {
            border-left: 1px solid $gray-200;
        }

        @media (--sm) {
            &::before {
                border-top: 1px solid $gray-200;
                content: "";
                display: block;
                margin: 50px auto;
                width: 160px;
            }
        }
    }
}
