$textBlack: #141110;
$textHighlight: #57321D;

$tablet: 768px;
$desktop: 1346px;

.editorial-homepage {

    padding: 0 12px;    
    border-width: 0 1px;
    border-style: solid;
    border-color: $textBlack;
    color: $textBlack;
    font-family: $font-family-sans;
    max-width: 1380px;
    margin: auto;

    @media screen and (min-width: $desktop) {
        padding: 0 20px;
    }

    * {
        box-sizing: border-box;
    }

    &__container {
        margin: 12px;
    }

    &__title {
        @include n4;
        text-align: justify;
        color: $textHighlight;
        padding-bottom: 80px;
        border-bottom: 1px solid $textBlack;        
    }

    &__article {
        display: grid;
        grid-template-areas: "title" "description" "image" "snippet";
        padding: 15px 0 30px 0;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        border-bottom: 1px solid $textBlack;
        padding-bottom: 20px;

        @media screen and (min-width: $tablet) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "image title" "image description" "image snippet" "image credits";
            grid-template-rows: 24px 33px 1fr 90px;
            grid-gap: 0 10px;

            &:nth-of-type(even) {
                grid-template-areas: "title image" "description image" "snippet image" "credits image";
            }
        }

        @media screen and (min-width: $desktop) {
            grid-template-rows: 33px 53px 1fr 90px;
            grid-gap: 0 15px;
        }

        &:hover {
            text-decoration: none;
        }

        &__title {
            grid-area: title;
            text-transform: uppercase;
            @include n4;
        }

        &__description {
            grid-area: description;
            @include n4;
        }

        &__image {
            grid-area: image;
            margin-top: 40px;
            width: 100%;
            object-fit: cover;

            @media screen and (min-width: $tablet) {
                margin-top: 0;
                width: 100%;
                height: 100%;
            }
        }

        &:not(:first-of-type) {
            img {
                max-height: 260px;

                @media screen and (min-width: $tablet) {
                    max-height: 267px;
                }

                @media screen and (min-width: $desktop) {
                    max-height: 508px;
                }
            }
        }

        &__snippet {
            grid-area: snippet;
            text-align: justify;            
            @include n6;

            &.has-snippet {
                margin-top: 20px;
            }

            @media screen and (min-width: $tablet) {
                padding: 5px;
                margin-top: 0;
            }

            @media screen and (min-width: $desktop) {
                padding: 0;
                margin-top: 40px;
            }
        }

        &__read-more {
            color: $textHighlight;            
            line-height: inherit;
            text-align: left;
            margin-top: 15px;

            .underline {
                text-decoration: underline;
            }            
        }

        &__credits {
            grid-area: credits;
            padding-left: 20px;            
            text-align: left;

            &, .credit {
                flex-direction: column;
                justify-content: flex-end;
                display: none;
                @include n6;
            }

            @media screen and (min-width: $tablet) {
                &, .credit {
                    display: flex;
                }
                padding-left: 5px;
            }
        }
    }

    &__load-more {
        padding: 80px 0;
        display: flex;
        justify-content: center;

        @media screen and (min-width: $desktop) {
            padding: 200px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            @include n8;
            text-align: center;
            border-radius: 0;
            border: 1px solid $textBlack;
            width: 223px;
            height: 40px;
            background: none;

            &:hover {
                color: $earthen-brown;
                border-color: $earthen-brown;
                text-decoration: none;
            }
        }
    }
}
