.l-homepage {
    overflow: hidden;

    @if ($feature-redesign) {
        overflow: visible;
    }

    .b-carousel__container::before {
        content: none;
    }
}

.b-carousel {
    &--product {
        &:not(.b-carousel--search) {
            margin-top: 60px;
        }
        margin-bottom: 60px;
    }

    &--product + &--product {
        margin-top: 100px;
    }
}
