@use "sass:math";

.b-articles {
    letter-spacing: 0.5px;
    line-height: 1.31;
    position: relative;

    &__content {
        margin: 0 auto;
        max-width: 1920px;

        @media (--sm) {
            padding-left: map-get($container-gutters, sm);
            padding-right: map-get($container-gutters, sm);
        }

        @media (--md) {
            padding-left: map-get($container-gutters, md);
            padding-right: map-get($container-gutters, md);
        }

        @media (--lg) {
            padding-left: map-get($container-gutters, lg);
            padding-right: map-get($container-gutters, lg);
        }

        @media (--xl) {
            padding-left: 60px;
            padding-right: 150px;
        }

        @media (--xxl) {
            padding-left: 172px;
            padding-right: 192px;
        }
    }

    &__first {
        width: 100%;

        @media (--md) {
            width: percentage(math.div(300, 700));
        }

        @media (--lg-up) {
            max-width: 512px;
            min-width: 410px;
            width: percentage(math.div(448, 1230));
        }
    }

    &__second {
        width: 100%;

        @media (--md) {
            width: percentage(math.div(300, 700));
        }

        @media (--lg-up) {
            max-width: 646px;
            min-width: 528px;
            width: percentage(math.div(588, 1230));
        }
    }

    &__third {
        width: 100%;

        .b-articles__image,
        picture:first-child {
            margin-right: 24px;

            @media (--sm) {
                margin-left: -#{map-get($container-gutters, sm)};
                min-width: 267px;
                width: percentage(math.div(267, 345));
            }

            @media (--md) {
                margin-left: -#{map-get($container-gutters, md)};
                width: percentage(math.div(434, 700));
            }

            @media (--lg) {
                margin-left: -#{map-get($container-gutters, lg)};
                width: percentage(math.div(588, 1080));
            }

            @media (--xl) {
                margin-left: -60px;
                width: percentage(math.div(606, 1230));
            }

            @media (--xxl) {
                margin-left: -172px;
                width: percentage(math.div(846, 1556));
            }
        }
    }

    &__image-title {
        height: 138px;
        width: auto;

        @media (--sm) {
            height: 78px;
        }
    }

    [class*='t-link'] {
        line-height: 1.5;
    }

    .t-link,
    .t-link--white,
    .t-link--gray {
        @include animated-link-hover;
    }

    .t-link--invert,
    .t-link--white-invert,
    .t-link--gray-invert {
        @include animated-link-hover($invert: true);
    }
}
