@use "sass:math";

.b-video {
    overflow: hidden;
    padding-bottom: percentage(math.div(9, 16));
    position: relative;

    > picture,
    > iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.m-ratio-1x1 {
        padding-bottom: percentage(math.div(1, 1));
    }

    &.m-ratio-3x2 {
        padding-bottom: percentage(math.div(2, 3));
    }

    &.m-ratio-4x3 {
        padding-bottom: percentage(3*0.25);
    }

    &.m-ratio-21x9 {
        padding-bottom: percentage(math.div(9, 21));
    }
}
